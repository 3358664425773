<template>
  <div id="fee-item" class="container">
    <template v-if="loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-6 is-offset-3">
          <ValidationObserver ref="observer" v-slot="{ passes }">
            <form @submit.prevent="passes(add)">
              <SdSelect
                rules="required"
                label="Item Type"
                v-model="feeItem.itemType"
              >
                <option v-for="itemType in itemTypes" :key="itemType">
                  {{ itemType }}
                </option>
              </SdSelect>

              <SdInput
                type="text"
                rules="required"
                label="Name"
                v-model="feeItem.name"
              />

              <SdInput
                type="text"
                rules="required"
                label="Amount"
                v-model="feeItem.amount"
              />

              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
              >
                Submit
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '@/components/SdInput'
import SdSelect from '@/components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '@/assets/js/notification'

export default {
  name: 'class-group',
  data() {
    return {
      pageTitle: 'Class Group',
      schoolId: null,
      skipQuery: true,
      feeItem: {
        id: null,
        name: null,
        itemType: null,
        amount: null,
      },
      itemTypes: ['Inventory', 'Non - Inventory'],
      loading: false,
    }
  },
  apollo: {
    feeItem: {
      query: gql`
        query FeeItemQuery($id: ID!) {
          feeItem(id: $id) {
            id
            name
            itemType
            amount
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  methods: {
    add() {
      const name = this.feeItem.name
      const itemType = this.feeItem.itemType
      const amount = parseFloat(this.feeItem.amount)
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createFeeItemQuery(
                $schoolId: Int!
                $name: String!
                $itemType: String!
                $amount: Float!
              ) {
                createFeeItem(
                  input: {
                    schoolId: $schoolId
                    name: $name
                    itemType: $itemType
                    amount: $amount
                  }
                ) {
                  feeItem {
                    id
                    name
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              schoolId: this.schoolId,
              name: name,
              itemType: itemType,
              amount: amount,
            },
            errors(error) {
              console.log(error)
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createFeeItem.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/fee_items`
              )
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateFeeItemQuery(
                $id: Int!
                $name: String!
                $itemType: String!
                $amount: Float!
              ) {
                updateFeeItem(
                  input: {
                    id: $id
                    name: $name
                    itemType: $itemType
                    amount: $amount
                  }
                ) {
                  feeItem {
                    id
                    name
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.feeItem.id),
              name: name,
              itemType: itemType,
              amount: amount,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateFeeItem.errors,
              'Successfully updated.'
            )
              .then(() => {
                this.$router.push(
                  `/school/${this.$route.params.school_id}/fee_items`
                )
              })
              .catch((error) => {
                console.error(error)
              })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Fee Items',
        route: `/school/${this.$route.params.school_id}/fee_items`,
      },
    ])
  },
}
</script>
